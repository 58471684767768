(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name teams.controller:TeamsCtrl
     *
     * @description
     *
     */
    angular
      .module('neo.home.teams')
      .controller('TeamsCtrl', TeamsCtrl);

    function TeamsCtrl($filter, $stateParams, EntityTeams, entities, categories, sports, genders, programmes) {
      var vm = this;
      vm.ctrlName = 'TeamsCtrl';

      vm.data = {
        title: 'TEAM_LIST',
        notFoundMessage: 'RESULT_NOT_FOUND',
        headers: [
          {id: 'name', name: 'NAME', canOrder: true, orderBy: 'nameOrder'},
          {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
          {id: 'sport', name: 'SPORT', canOrder: true},
          {id: 'gender', name: 'GENDER', canOrder: true},
          {id: 'programType', name: 'PROGRAM_TYPE', canOrder: true}
        ],
        areRowsClickable: true,
        state: {
          slink: 'home.teams.team', params: [
            {key: 'teamId', value: 'id'}
          ]
        },
        defaultOrderBy: 'nameOrder',
        filters: [
          {
            label: 'ENTITIES.SELECT',
            showField: 'name',
            filterParam: 'entityId',
            filterField: 'id',
            required: true,
            options: entities
          }
        ],
        filterValues: {
          ENTITIES: $stateParams.selectedEntity
        },
        filterPromise: function (params) {
          return EntityTeams.query({entityId: params.entityId}).$promise;
        },
        filterFunction: function (data) {
          angular.forEach(data, function (team) {
            var category = findCategory(team.categoryId);
            team.nameOrder = $filter('lowercase')(team.name);
            team.category = $filter('translate')('CATEGORIES.' + category.name);
            team.categoryOrder = category.startYear;
            team.sport = $filter('translate')('SPORTS.' + findSport(team.sportId).name);
            team.gender = $filter('translate')(findGender(team.genderId).name);
            team.programType = $filter('translate')(findProgramme(team.programmeId).name);
          });
          return data;
        }
      };

      var hasSelectedItem = function (filter) {
        return angular.isDefined(filter.selectedItem) && filter.selectedItem !== null;
      };

      vm.getEntity = function () {
        if (hasSelectedItem(vm.data.filters[0])) {
          return vm.data.filters[0].selectedItem.id;
        } else {
          return undefined;
        }
      };

      var findCategory = function (categoryId) {
        return _.find(categories, function (c) {
          return c.id === categoryId;
        });
      };

      var findSport = function (sportId) {
        return _.find(sports, function (s) {
          return s.id === sportId;
        });
      };

      var findGender = function (genderId) {
        return _.find(genders, function (g) {
          return g.id === genderId;
        })
      };

      var findProgramme = function (programmeId) {
        return _.find(programmes, function (p) {
          return p.id === programmeId;
        });
      };

    }
  }()
);
